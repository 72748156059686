import 'components/AdminLayout/scss/grid.scss';
import 'components/AdminLayout/scss/text-blc.scss';

import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./config/keycloak"
import { useUnit } from "effector-react";

import { $user} from 'models/user';
import { KeycloakTokenParsed } from 'keycloak-js';

import AppRouter from './AppRouter'
import { useNavigate } from 'react-router-dom';

const eventLogger = (event: unknown, error: unknown) => {
	console.log('onKeycloakEvent', event, error)
}

function App() {

	const user = useUnit($user);
	const navigate = useNavigate();

	const tokenLogger = (tokens: any) => {
		console.log('onKeycloakTokens', tokens)
	  
		const tokenParsed:KeycloakTokenParsed= keycloak.tokenParsed!;

		console.log('APP Token Parsed:', {tokenParsed, user, if: tokenParsed && user && tokenParsed?.groups?.length !== user?.groups?.length});	

		if (tokenParsed && user && tokenParsed?.groups?.length !== user?.groups?.length) {
			const encodedRef = encodeURIComponent(location.pathname)
			navigate(`/login?ref=${encodedRef}`);
		}
	}

	console.log('App',{user})

	return (		
		<ReactKeycloakProvider
			authClient={keycloak}
			onEvent={eventLogger}
			onTokens={tokenLogger}
		>
			<AppRouter />
		</ReactKeycloakProvider>
	);
}


export default App;