import React from 'react';
import useFetch from 'use-http';
import Button from 'components/UI/Button';
import { useParams, useNavigate } from 'react-router-dom';
import Scanner from 'components/Scanner';
import { Html5QrcodeSupportedFormats } from "html5-qrcode";
import { useUnit } from 'effector-react';
import { $coordinate, $noCoord, $token } from 'models/user';
import { registrCheckin } from 'utils/helpers';

const qrConfig = {
  fps: 10,
  qrbox: { width: 500, height: 500 },
  formatsToSupport: [
    Html5QrcodeSupportedFormats.QR_CODE
  ]
};

export default function ScannerPage() {
	const { post, error } = useFetch();
	const navigate = useNavigate();
	const { mode } = useParams();
	const token = useUnit($token)
	const noCoord = useUnit($noCoord)
	const coordinate = useUnit($coordinate)
	const [loading, setLoading] = React.useState(false);
	const [localError, setLocalError] = React.useState<string | null>(null);

	const successHandle = React.useCallback(
		async (code: string) => {

				const fetchData = async () => {
					setLoading(true);
					setLocalError(null);

					try {
						const diffGeoRes = await post('/api/checkin/diffGeo', {
							code,
							lat: coordinate?.latitude,
							lon: coordinate?.longitude,
						})
						if (diffGeoRes.statusCode) {
							throw new Error(diffGeoRes.message || 'Ошибка при сохранении уведомления нарушения координат')
						}

						if (noCoord) {
							navigate(`/photo/${code}`)
						} else if (!coordinate?.latitude || !coordinate?.longitude || diffGeoRes) {
							navigate(`/maps/${code}`)
						} else {
							await registrCheckin(post, code, token, coordinate)
							navigate(`/`)
						}
					} catch (err) {
						if (err instanceof Error) {
							setLocalError(err.message);
						} else {
							setLocalError('Неизвестная ошибка');
						}
						console.error(err);
					} finally {
					  setLoading(false);
					}
				};
				fetchData();
		},
		[coordinate, token, navigate, setLocalError, setLoading],
	);

	React.useEffect(() => {
		if (localError ||  error) {
			alert(localError || error?.message)
			navigate('/');
		}		
	}, [localError, error])

	return (
		<div style={{minWidth: '250px', minHeight: '250px'}}>
			{loading ? (
				<div>Загрузка...</div>
			) : (
				<>
					<Scanner successCallback={successHandle} mode={mode} config={qrConfig} facingModeUser={false} />
					<Button variant="green" type="button" onClick={() => navigate('/')} style={{ margin: '40px auto' }}>
						Вернуться к списку смен
					</Button>

					{/* <button onClick={async () => successHandle('LeUFalfWp8uUlCQ')}>Click</button> */}
				</>
			)}
		</div>
	);
}
