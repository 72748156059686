import { useState, useEffect } from 'react';

interface IGeoError {
	code: number;
	message: string;
	PERMISSION_DENIED: number;
	POSITION_UNAVAILABLE: number;
	TIMEOUT: number;
}

class GeoCoords {
	latitude?: number;
	longitude?: number;
	altitude?: number | null;
	accuracy?: number | null;
	altitudeAccuracy?: number | null;
	heading?: number | null;
	speed?: number | null;
}

const TIME = 60000

export const usePosition = () => {
	const [position, setPosition] = useState(new GeoCoords());
	const [error, setError] = useState<string>();

	const handleSuccess = ({ coords }: { coords: GeoCoords }) => {
		setPosition({
			latitude: coords.latitude,
			longitude: coords.longitude,
		});
	};

    const handleError = (err: IGeoError) => {
		switch (err.code) {
		  case err.PERMISSION_DENIED:
			setError("Пользователь запретил доступ к геолокации.");
			break;
		  case err.POSITION_UNAVAILABLE:
			setError("Информация о местоположении недоступна.");
			break;
		  case err.TIMEOUT:
			setError("Время ожидания истекло.");
			break;
		  default:
			setError("Произошла неизвестная ошибка.");
			break;
		}
	};	

	const requestPosition = () => {
		const geo = navigator.geolocation;
		if (!geo) {
		  setError('Информация о местоположении недоступна.');
		  return;
		}
		geo.getCurrentPosition(handleSuccess, handleError, {});
	};

	useEffect(() => {
		const geo = navigator.geolocation;
		if (!geo) {
			setError('Geolocation is not supported');
			return;
		}

		// Запрашиваем координаты каждую секунду в течение TIME секунд
		const intervalId = setInterval(() => {
			requestPosition();
		}, 1000);

		// Остановить запрос через TIME секунд
		const timeoutId = setTimeout(() => {
			clearInterval(intervalId);
		}, TIME);

		const watcher = geo.watchPosition(handleSuccess, handleError, {
			timeout: TIME,  // Таймаут на получение координат
			maximumAge: TIME,  // Не использовать кэшированные данные
		});

		return () => {
			clearInterval(intervalId);
			clearTimeout(timeoutId);
			geo.clearWatch(watcher);
		};		
	}, []);

	return { position, error, requestPosition };
};
