// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap--RLXFI{height:50%;margin:0 auto}.top-side--ewoWz{height:80px;mix-blend-mode:normal;border-radius:6px}.top-side-left--a35Hk{top:20px;left:20px;position:absolute}.top-side-right--yjtUz{position:absolute;right:20px;top:20px}.body-side--Q5FX8{margin:auto;text-align:center}.counter--Mp462{font-family:\"Roboto\";font-style:normal;color:#fff;position:absolute;top:60px;left:25px}.counter-value--Wkx94{font-weight:700;font-size:40px;line-height:47px;letter-spacing:.285714px}.counter-value--black--Ivqpz{color:#535d7e}.counter-label--black--MAQGh{color:#535d7e}", "",{"version":3,"sources":["webpack://./src/components/Layout/styles.module.scss"],"names":[],"mappings":"AAAA,aACI,UAAA,CACA,aAAA,CAGF,iBACE,WAAA,CACA,qBAAA,CACA,iBAAA,CAGF,sBACE,QAAA,CACA,SAAA,CACA,iBAAA,CAGF,uBACE,iBAAA,CACA,UAAA,CACA,QAAA,CAGF,kBACE,WAAA,CACA,iBAAA,CAGF,gBACE,oBAAA,CACA,iBAAA,CACA,UAAA,CACA,iBAAA,CACA,QAAA,CACA,SAAA,CACA,sBACE,eAAA,CACA,cAAA,CACA,gBAAA,CACA,wBAAA,CACA,6BACE,aAAA,CAIF,6BACE,aAAA","sourcesContent":[".wrap {\n    height: 50%;\n    margin: 0 auto;\n  }\n  \n  .top-side {\n    height: 80px;\n    mix-blend-mode: normal;\n    border-radius: 6px;\n  }\n  \n  .top-side-left {\n    top: 20px;\n    left: 20px;\n    position: absolute;\n  }\n  \n  .top-side-right {\n    position: absolute;\n    right: 20px;\n    top: 20px;\n  }\n\n  .body-side {\n    margin: auto;\n    text-align: center;\n  }\n  \n  .counter {\n    font-family: 'Roboto';\n    font-style: normal;  \n    color: #FFFFFF;  \n    position: absolute;\n    top: 60px;\n    left: 25px;\n    &-value {\n      font-weight: 700;\n      font-size: 40px;\n      line-height: 47px;\n      letter-spacing: 0.285714px;  \n      &--black {\n        color: #535D7E;\n      }\n    }\n    &-label {\n      &--black {\n        color: #535D7E;\n      }\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "wrap--RLXFI",
	"top-side": "top-side--ewoWz",
	"top-side-left": "top-side-left--a35Hk",
	"top-side-right": "top-side-right--yjtUz",
	"body-side": "body-side--Q5FX8",
	"counter": "counter--Mp462",
	"counter-value": "counter-value--Wkx94",
	"counter-value--black": "counter-value--black--Ivqpz",
	"counter-label--black": "counter-label--black--MAQGh"
};
export default ___CSS_LOADER_EXPORT___;
