import ReactPaginate from 'react-paginate';
import ArrowBack from 'assets/img/svg/arrow_back_ios_black_24dp.svg';
import ArrowUp from 'assets/img/svg/arrow_forward_ios_black_24dp.svg';
import classes from './styles.module.scss';

export default function Paginate({
	handlePageClick,
	pageCount,
}: {
	handlePageClick: ({ selected }: { selected: number }) => void;
	pageCount: number;
}) {
	return (
		<ReactPaginate
			breakLabel="..."
			onPageChange={handlePageClick}
			pageRangeDisplayed={3}
			marginPagesDisplayed={1}
			pageCount={pageCount}
			previousLabel={<img alt="previous" src={ArrowBack} />}
			nextLabel={<img alt="next" src={ArrowUp} />}
			breakClassName={classes.pagination__item}
			className={classes.pagination}
			pageClassName={classes.pagination__item}
			activeClassName={classes.active}
			previousClassName={classes.pagination__arrow}
			nextClassName={classes.pagination__arrow}
			// pageLabelBuilder={Page}
			// renderOnZeroPageCount={handleZero}
			// hrefBuilder={handleHrefBuilder}
			// ariaLabelBuilder={RenderLabel}
		/>
	);
}
