import * as Sentry from "@sentry/react";
import { createRoot } from "react-dom/client";
import App from './App';
import './styles.scss';
import { BrowserRouter } from 'react-router-dom';

Sentry.init({
  dsn: "https://ab22991810800c0219870cb7f2ee2083@o4507288213454848.ingest.de.sentry.io/4507288216862800",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^http:\/\/localhost\:3000/,/^https:\/\/smena\.webdev\.famil\.ru/,/^https:\/\/smena\.famil\.ru/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const container: HTMLElement | null = document.getElementById('root');

if (container) {
  const root = createRoot(container);

  root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}
