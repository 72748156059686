import React from 'react';
import useFetch from 'use-http';
import { useNavigate } from 'react-router-dom';
import Paginate from 'components/UI/Paginate';
import usePaginate from 'hooks/usePaginate';
import Table from 'components/UI/Table';
import Input from 'components/UI/Input';
import Button from 'components/UI/Button';
import { IStores } from 'types/stores';
import { useUnit } from 'effector-react';
import { $store } from 'models/store';
import { GroupsEnum } from 'types/users';
import { $user } from 'models/user';
const perPage = 10;

export default function Stores() {
	const { get, loading, error } = useFetch();
	const store = useUnit($store)
	const user = useUnit($user)

	const [data, setData] = React.useState([]);
	const { pageCount, offset, setCount, handlePageClick } = usePaginate(perPage);

	const load = async (filter = '') => {

		let storeId = null

		if (user && user.groups && user.groups.indexOf(GroupsEnum.ADMIN) > -1) {
			storeId = ''
		} else if (store?.id) {
			storeId = store?.id
		}

		if (storeId !== null) {
			const result = await get(`/api/stores?offset=${offset}&limit=${perPage}&filter=${filter}&storeId=${storeId}`);
			if (result?.rows) {
				setData(result.rows);
				setCount(result.count);
			}
		}
	}

	React.useEffect(() => {
		load();
	}, [offset, perPage, store, user]);

	const navigate = useNavigate();

	const headers = [
		{ value: 'number', title: 'Номер' },
		{ value: 'title', title: 'Название' },
		{ value: 'address', title: 'Адрес' },
		{ value: 'manager', title: 'Руководитель' },
		{
			value: 'operation',
			title: '',
			width: '300px',
			formatted: React.useCallback(
				(row: IStores) => {
					const routeChange = () => {
						const path = `/admin/stores/${row.id}/sessions`;
						navigate(path);
					};

					return (
						<div className="row">
							<div className="column-xs-6">
								<Button size="sm" variant="secondary" onClick={routeChange}>
									Статистика присутствия
								</Button>
							</div>
						</div>
					);
				},
				[navigate],
			),
		},
	];

	const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		await load(event.target.value);
	};

	return (
		<div>
			{error && 'Error!'}
			<div className="row">
				<div className="column-md-3">
					<Input onChange={handleChange} name="filter" label="Поиск" />
				</div>
			</div>
			<Table headers={headers} rows={data} />
			{pageCount > 1 && <Paginate handlePageClick={handlePageClick} pageCount={pageCount} />}
		</div>
	);
}
