// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".maps-wrap--K9IE4{margin:auto;bottom:0;position:absolute;width:320px;left:0;right:0;top:0;right:0}", "",{"version":3,"sources":["webpack://./src/pages/Maps/styles.module.scss"],"names":[],"mappings":"AAAA,kBACI,WAAA,CACA,QAAA,CACA,iBAAA,CACA,WAAA,CACA,MAAA,CACA,OAAA,CACA,KAAA,CACA,OAAA","sourcesContent":[".maps-wrap {\n    margin: auto;\n    bottom: 0;\n    position: absolute;\n    width: 320px;\n    left: 0;\n    right: 0;\n    top: 0;\n    right: 0;    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"maps-wrap": "maps-wrap--K9IE4"
};
export default ___CSS_LOADER_EXPORT___;
