// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".section-list--XYpN2{display:flex;flex-direction:column;gap:10px;justify-content:center;align-items:center;margin:100px 0;width:100%}.section-item--l8EEc{display:flex;width:100%;padding:0 20px;flex-direction:row;justify-content:space-evenly}.section-item-btn--FwFtY{display:flex}", "",{"version":3,"sources":["webpack://./src/pages/Section/styles.module.scss"],"names":[],"mappings":"AACI,qBACI,YAAA,CACA,qBAAA,CACA,QAAA,CACA,sBAAA,CACA,kBAAA,CACA,cAAA,CACA,UAAA,CAEJ,qBACI,YAAA,CACA,UAAA,CACA,cAAA,CACA,kBAAA,CACA,4BAAA,CACA,yBACI,YAAA","sourcesContent":[".section {\n    &-list {\n        display: flex;\n        flex-direction: column;\n        gap: 10px;\n        justify-content: center;\n        align-items: center;\n        margin: 100px 0;\n        width: 100%;\n    }\n    &-item {\n        display: flex;\n        width: 100%;\n        padding: 0 20px;\n        flex-direction: row;\n        justify-content: space-evenly;        \n        &-btn {\n            display: flex;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section-list": "section-list--XYpN2",
	"section-item": "section-item--l8EEc",
	"section-item-btn": "section-item-btn--FwFtY"
};
export default ___CSS_LOADER_EXPORT___;
