import { ForwardedRef } from 'react';
import { FieldError, RefCallBack } from 'react-hook-form';
import classes from './styles.module.scss';

export default function Input({
	min,
	label,
	onChange,
	onBlur,
	type = 'text',
	size = 'md',
	placeholder = '',
	onFocus,
	error,
	name,
	withLabel = true,
	defaultValue = null,
	innerRef,
	disabled = false,
}:
{
	min?: number | string | undefined;
	name: string;
	label?: React.ReactNode;
	onChange?: (...event: any[]) => (void | Promise<void>) | React.ChangeEventHandler<HTMLInputElement> | undefined;
	onBlur?: (...event: any[]) => void | Promise<void>;
	type?: string;
	size?: 'md' | 'sm' | 'lg';
	placeholder?: string;
	onFocus?: (...event: any[]) => void | Promise<void>;
	error?: FieldError | undefined;
	withLabel?: boolean | undefined;
	defaultValue?: any;
	innerRef?: undefined | RefCallBack;
	disabled?: boolean; 
}) {
	return (
		<label htmlFor="input" className={withLabel ? classes.label : ''}>
			{label && <span className={classes.label__title}>{label}</span>}
			<input
				ref={innerRef}
				id="input"
				autoComplete="off"
				onChange={onChange}
				onBlur={onBlur}
				type={type}
				name={name}
				className={`${classes.input} ${error && classes.error} ${classes[`input--${size}`]}`}
				min={min}
				placeholder={placeholder}
				// TODO onFocus нужен для InputMask
				onFocus={onFocus}
				defaultValue={defaultValue}
				disabled={disabled ? true : false}
			/>
			{error && <small className={classes.label__error}>{error.message}</small>}
			{/* <small className="label__txt">Текст примечания</small> */}
		</label>
	);
}
