import React, { Dispatch, ReactNode } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import Textarea from 'components/UI/Textarea';
import Button from 'components/UI/Button';

interface IRejectForm<T> {
	row: T;
	init: () => Promise<void>;
	update: (data: FormInputs) => Promise<void>;
	setDisplay: Dispatch<boolean>;
}

export interface FormInputs {
	id: number;
	commentRd: string;
}

const RejectForm = <T extends object>({ row, init, update, setDisplay }: IRejectForm<T & { id: number }>) => {
	const { handleSubmit, control, register } = useForm<FormInputs>();

	const onSubmit: SubmitHandler<FormInputs> = async data => {
		await update(data);
		await init();
		setDisplay(false);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="row">
				<div className="column-xs-12">
					<Controller
						name={`commentRd`}
						control={control}
						rules={{ required: 'Обязательное поле' }}
						render={({ field, fieldState }) => (
							<Textarea
								onChange={field.onChange}
								onBlur={field.onBlur}
								name={field.name}
								error={fieldState.error}
								defaultValue={field.value}
							/>
						)}
					/>
				</div>
				<input type="hidden" value={row.id} {...register('id')} />
				<div className="column-xs-12">
					<div className="row">
						<div className="column-xs-6">
							<Button type="submit" size="sm">
								Отправить
							</Button>
						</div>
						<div className="column-xs-6">
							<Button type="submit" size="sm" onClick={() => setDisplay(false)}>
								Отменить
							</Button>
						</div>
					</div>
				</div>
			</div>
		</form>
	);
};

interface IOperationFroms<T> {
	row: T;
	init: () => Promise<void>;
	update: (data: FormInputs) => Promise<void>;
}

const OperationFrom = <T extends object>({
	row,
	init,
	update,
}: IOperationFroms<T & { commentRd?: string; id: number }> & { children?: ReactNode }) => {
	const [display, setDisplay] = React.useState<boolean>(false);

	return (
		<div>
			{row.commentRd && row.commentRd}
			{!display && (
				<Button onClick={() => setDisplay(true)} size="sm" variant="secondary">
					Комментировать
				</Button>
			)}

			{display && <RejectForm row={row} init={init} setDisplay={setDisplay} update={update} />}
		</div>
	);
};

export default OperationFrom;
