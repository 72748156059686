import Select, {
	components,
	MenuListProps,
	MenuProps,
	ControlProps,
	DropdownIndicatorProps,
	IndicatorSeparatorProps,
	CSSObjectWithLabel,
	MultiValue,
	SingleValue,
} from 'react-select';
import { FieldError } from 'react-hook-form';
import classes from './styles.module.scss';

export interface ISelectOptions {
	value: string;
	label: string;
}
export function isMultiValue(
	item: MultiValue<ISelectOptions> | SingleValue<ISelectOptions>,
): item is MultiValue<ISelectOptions> {
	return false;
}

const customStyles = {
	menuList: (provided: CSSObjectWithLabel) => ({
		...provided,
		paddingBottom: 0,
		paddingTop: 0,
	}),
	clearIndicator: () => ({
		marginTop: '4px',
	}),
	control: (provided: CSSObjectWithLabel) => ({
		...provided,
		borderStyle: 'hidden',
		borderBottom: '1.5px solid rgba(97,66,117,.1)',
		borderRadius: 0,
		':hover': {
			borderColor: 'rgba(97,66,117,.1)',
		},
		minHeight: 'auto',
		boxShadow: 'none',
	}),
	option: (provided: CSSObjectWithLabel, { isFocused }: { isFocused: boolean }) => ({
		...provided,
		backgroundColor: isFocused ? 'rgba(0, 0, 0, 0.16)' : 'white',
		color: isFocused ? '#000 !important' : '#6d6e6f',
	}),
	menu: (provided: CSSObjectWithLabel) => ({
		...provided,
		marginTop: 0,
	}),
};

const Menu = (props: MenuProps<ISelectOptions>) => {
	return <components.Menu {...props}>{props.children}</components.Menu>;
};

const MenuList = (props: MenuListProps<ISelectOptions>) => {
	return (
		<components.MenuList {...props} className={classes['select-items']}>
			{props.children}
		</components.MenuList>
	);
};

const Control = (props: ControlProps<ISelectOptions>) => {
	const { selectProps } = props;
	return (
		<components.Control
			{...props}
			className={`${classes['select-selected']} ${selectProps.menuIsOpen && classes['select-arrow-active']}`}
		>
			{props.children}
		</components.Control>
	);
};

const DropdownIndicator = (props: DropdownIndicatorProps<ISelectOptions>) => {
	return null;
};

const IndicatorSeparator = ({ innerProps }: IndicatorSeparatorProps<ISelectOptions>) => {
	return null;
};

export default function CustomSelect({
	options,
	onChange,
	placeholder = '',
	error,
	isClearable = false,
	label,
	defaultValue,
	isSearchable = false,
}: {
	options: ISelectOptions[];
	onChange: (value: any) => void;
	placeholder?: string;
	error?: FieldError | undefined;
	isClearable?: boolean;
	label?: React.ReactNode;
	defaultValue?: number | string;
	isSearchable?: boolean
}) {
	const handleChange = (newValue: MultiValue<ISelectOptions> | SingleValue<ISelectOptions>): void => {
		if (isMultiValue(newValue) || !newValue) {
			onChange(newValue);
		} else {
			onChange(newValue.value);
		}
	};

	return (
		<label className={classes.label}>
			{label && <div className={classes['label__title']}>{label}</div>}
			<Select
				styles={customStyles}
				className={`${classes['styled-select']} ${classes['styled-select--md']}`}
				options={options}
				// menuIsOpen
				isSearchable={isSearchable}
				isClearable={isClearable}
				onChange={handleChange}
				placeholder={placeholder}
				noOptionsMessage={() => <>Не найдено</>}
				loadingMessage={() => <>Загружается</>}
				components={{ MenuList, Menu, Control, DropdownIndicator, IndicatorSeparator }}
				value={options.find((option: ISelectOptions) => option.value === defaultValue)}
			/>
			{error && <small className={classes['label__error']}>{error.message}</small>}
		</label>
	);
}
