import React from 'react';

export default function usePaginate(perPage: number) {
	const [pageCount, setPageCount] = React.useState(0);
	const [count, setCount] = React.useState(0);
	const [offset, setOffset] = React.useState(0);

	React.useEffect(() => {
		setPageCount(Math.ceil(count / perPage));
	}, [offset, perPage, count]);

	const handlePageClick = (event: { selected: number }) => {
		const newOffset = (event.selected * perPage) % count;
		setOffset(newOffset);
	};

	return { pageCount, offset, setCount, handlePageClick };
}
