import React from 'react';
import styles from './styles.module.scss';
import OutsTable from './OutsTable';
import { useNavigate, useParams } from 'react-router-dom';
import { $qrcode, $store, setQRCode } from 'models/store';
import { useUnit } from 'effector-react';
import useFetch from 'use-http';
import Button from 'components/UI/Button';
import { IOutsourcing } from 'types/outsourcing';
import { ISession } from 'types/session';
import SessionTable from './SessionTable';


export default function AcceptCheckin() {
    const { outsourceId, mode, imageId, sessionId } = useParams();
    const navigate = useNavigate();
    const { post, get } = useFetch();

    const [ loading, setLoading ] = React.useState(false)

    const [session, setSession] = React.useState<ISession|undefined>()

	React.useEffect(() => {
		init();
	}, [sessionId]);

	async function init() {
        if (sessionId) {
            const result = await get(`/api/session/${sessionId}`);
            setSession(result)    
        }
	}    

    const store = useUnit($store);
    const qrcode = useUnit($qrcode);

	const getCode = React.useCallback(
        async () => {
            if (store) {
                const qrcodeData = await get(`/api/qrcode/byStoreId?storeId=${store.id}`);
                setQRCode(qrcodeData.code)
                return qrcodeData
            }		    
	    }, [store, setQRCode]
    )

    React.useEffect(() => {
        if (!qrcode  && store) {
            getCode();
        }
    }, [qrcode, store])    

    const [outsource, setOutsource] = React.useState<IOutsourcing|undefined>()

	const loadOutsource = React.useCallback(
        async () => {
            const outsourceRes = await get(`/api/outsourcing/${outsourceId}`);
            setOutsource(outsourceRes)
	    }, [outsourceId]
    )

    React.useEffect(() => {
        loadOutsource()
    }, [outsourceId])

    const handleSave = React.useCallback(async () => {

        setLoading(true)
        const currentDate = new Date();
        if (outsource) {

            const checkin = await post('/api/checkin/registrAnother', {
                code: qrcode,
                userId:  outsource?.user?.id,
                fileId: imageId !== "-1" ? imageId : null,
                outsourcingId: outsourceId,
                type: 'outsource',
                datetimeStr: `${currentDate}`,
                timezoneOffset: currentDate.getTimezoneOffset(),
                sessionId: sessionId,
                mode
            });
        }
        setLoading(false)
        navigate('/outsourcing');
	}, [qrcode, setLoading, imageId, outsource]);   
    
    const date = new Date()

	return (
		<div className={styles["section-list"]}>
            <div className='row'>
                <div className="column-xs-12">
                    <h2>{mode === 'open' ? 'Вы хотите открыть смену сотруднику' : 'Закрыть смену?'}</h2>
                    {session?.outsourcing ? <SessionTable data={session}  curDateTime={date} /> : <OutsTable data={outsource} />}
                </div>
            </div>
            <div className="row">
                <div className="column-xs-4">
                    <Button variant="violet" type="button" onClick={() => navigate('/qrcode')} style={{ margin: 'auto' }}>
                        Назад
                    </Button>
                </div>
                <div className="column-xs-4">
                    <Button variant="green" disabled={loading} type="button" onClick={handleSave} style={{ margin: 'auto' }}>
                        {loading ? "Загрузка..." : "Подтвердить"}
                    </Button>
                </div>
                </div>
        </div>
    )
}