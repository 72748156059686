import React from 'react';
import classes from './styles.module.scss';

export default function Button({
	children,
	size = 'md',
	onClick,
	type = 'button',
	disabled = false,
	variant = 'primery',
	endIcon,
	style,
	block = false,
}: {
	children?: React.ReactNode;
	size?: 'sm' | 'md' | 'lg';
	onClick?: (...event: any[]) => void | Promise<void>;
	type?: 'button' | 'reset' | 'submit';
	disabled?: boolean;
	variant?: 'primery' | 'secondary' | 'violet' | 'green' | 'orange' | null;
	endIcon?: JSX.Element | undefined;
	style?: object | undefined;
	block?: boolean | undefined;
}) {
	return (
		<button
			style={style && style}
			onClick={onClick && onClick}
			// className={`btn btn--${size} btn-${variant}`}
			className={`${classes.btn} ${classes[`btn--${size}`]} ${variant && classes[`btn-${variant}`]} ${
				block && classes[`btn--block`]
			}`}
			type={type}
			disabled={disabled}
		>
			{/* <i><img src="img/svg/icon-example.svg" alt="" /></i> */}
			{children && <span>{children}</span>}
			{endIcon}
		</button>
	);
}

// Button.propTypes = {
// 	size: 'md',
// 	// bar: PropTypes.string.isRequired
// };
