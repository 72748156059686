import { FieldError } from 'react-hook-form';
import classes from '../Input/styles.module.scss';

export default function Input({
	label,
	onChange,
	onBlur,
	placeholder = '',
	onFocus,
	error,
	name,
	defaultValue = null,
	maxlength = 10000,
}: {
	label?: string;
	onChange: (...event: any[]) => void | Promise<void>;
	onBlur?: (...event: any[]) => void | Promise<void>;
	placeholder?: string;
	onFocus?: (...event: any[]) => void | Promise<void>;
	error: FieldError | undefined;
	name: string;
	defaultValue?: any;
	maxlength?: number;
}) {
	return (
		<label htmlFor="textarea" className={classes.label}>
			{label && <span className={classes.label__title}>{label}</span>}
			<textarea
				style={{
					border: '1px solid #edeae9',
					width: '100%',
				}}
				id="textarea"
				autoComplete="off"
				maxLength={maxlength}
				onChange={onChange}
				onBlur={onBlur}
				name={name}
				className={`${error && classes.error}`}
				placeholder={placeholder}
				// TODO onFocus нужен для InputMask
				onFocus={onFocus}
				defaultValue={defaultValue}
			/>
			{error && <small className={classes.label__error}>{error.message}</small>}
			{/* <small className="label__txt">Текст примечания</small> */}
		</label>
	);
}
