import classes from '../Header/styles.module.scss';
import { VERSION } from '../../../utils/constants'
import { GroupsEnum } from 'types/users';
import {  Link } from 'react-router-dom';
import { useUnit } from 'effector-react';
import { $user } from 'models/user';
import Logout from './Logout';
import { $store, $storeList } from 'models/store';

export default function ProfileMenu() {
    const user = useUnit($user);
    const store = useUnit($store);
    const storeList = useUnit($storeList);
	
	return (
        <ul>
            <li>
                <Link  className={classes['drop-nav-link']} to="/">
                    <span className={classes['drop-nav-link__txt']}>Главная страница</span>
                </Link>
            </li>
            {
                user &&
                store?.id &&
                Array.isArray(user.groups) &&
                (
                    user.groups.indexOf(GroupsEnum.GEN_QRCODE) > -1 ||
                    user.groups.indexOf(GroupsEnum.MANUAL_REG) > -1 ||
                    user.groups.indexOf(GroupsEnum.SESSION_STAT) > -1
                ) && storeList?.length > 0 && 
            (
                <li>
                    <Link  className={classes['drop-nav-link']} to="/qrcode">
                        <span className={classes['drop-nav-link__txt']}>Панель магазина</span>
                    </Link>
                </li>
            )}
            {user && Array.isArray(user.groups) && 
                (
                    user?.groups.indexOf(GroupsEnum.ADMIN) > -1 ||
                    ((user.groups.indexOf(GroupsEnum.SESSION_STAT) > -1 || user.groups.indexOf(GroupsEnum.BREACH_STAT) > -1) && storeList?.length > 0)
                ) && (
                <li>
                    <Link  className={classes['drop-nav-link']} to="/admin">
                        <span className={classes['drop-nav-link__txt']}>Панель администратора</span>
                    </Link>                    
                </li>
            )}
            {user && Array.isArray(user.groups) && user?.groups.indexOf(GroupsEnum.ADMIN) > -1 && (<Logout />)}
            <li>
                <div className={classes['profile-bar__version']}>Версия: {VERSION}.{process.env.REACT_APP_CI_COMMIT_SHORT_SHA}</div>
            </li>
        </ul>
	);
}
