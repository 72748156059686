import { Outlet } from 'react-router-dom';
import Header from './Header';
import Sidebar from './Sidebar';
import classes from './styles.module.scss';

export default function AdminLayout() {
	return (
		<div className={classes.wrapper}>
			<Header />
			<Sidebar />
			<main className={classes.main}>
				<div className={classes.content}>
					<Outlet />
				</div>
			</main>
		</div>
	);
}
