import 'react-datepicker/dist/react-datepicker.css';
import React from 'react';
import useFetch from 'use-http';
import { useParams } from 'react-router-dom';
import Paginate from 'components/UI/Paginate';
import Table, { TableFilter } from 'components/UI/Table';
import usePaginate from 'hooks/usePaginate';
import moment from 'moment';
import { ISession } from 'types/session';
import { convertUser } from 'utils/helpers';
import { ISelectOptions } from 'types/utils';
import AsyncSelect from 'components/UI/AsyncSelect';
import Button from 'components/UI/Button';
import DatePicker from 'react-datepicker';
import OperationFrom from 'components/OperationFrom';
import FileDownload from 'assets/img/svg/download.svg';
import ZoomComponent from 'components/UI/Zoom';
import { useUnit } from 'effector-react';
import { $storeStr } from 'models/store';

const perPage = 10;
const host = process.env.REACT_APP_BACKEND_URL;

export default function Sessions() {
	const { storeId } = useParams();
	const { patch, get, loading, error } = useFetch();

	const [data, setData] = React.useState([]);
	const [userList, setUserList] = React.useState([]);
	const [filter, setFilter] = React.useState<TableFilter>(new TableFilter());
	const { pageCount, offset, setCount, handlePageClick } = usePaginate(perPage);
	const storeStr = useUnit($storeStr)
	const load = async () => {
		const params = Object.entries(filter)
			.map(item => item.join('='))
			.join('&');
		const result = await get(`/api/session?offset=${offset}&limit=${perPage}&storeId=${storeId}&${params}`);
		if (result?.rows) {
			setData(result.rows.map((row: any) => ({ ...row, uri: row?.preview?.uri ? row.preview.uri : null })));
			setCount(result.count);
		}
	};

	React.useEffect(() => {
		init();
	}, []);

	async function init() {
		const resultUser = await get(`/api/users`);
		setUserList(resultUser);
		const previousDate = moment().subtract(1, 'days').startOf('day').toDate();
		setFilter({fromDate: previousDate})
	}

	React.useEffect(() => {
		load();
	}, [offset, perPage, filter]);

	async function loadUser(inputValue: string, callback: (options: ISelectOptions[]) => void) {
		let data = [];
		const params = new URLSearchParams({
			fio: inputValue,
		});
		if (inputValue) {
			data = await get(`/api/users?${params.toString()}`);
		}

		return convertUser(data);
	}

	const headers = [
		{ value: 'id', title: 'ID' },
		{
			value: 'user',
			title: 'ФИО',
			formatted: React.useCallback((row: any) => {
				return (
					<div>
						<span>{row.user?.firstname} </span>
						<span>{row.user?.secondname} </span>
						<span>{row.user?.middlename} </span>
					</div>
				);
			}, []),
			filter: React.useCallback(() => {
				const handleChange = (newValue: any): void => {
					setFilter({ ...filter, userId: newValue });
				};

				return (
					<AsyncSelect width="200px" onChange={handleChange} load={loadUser} isClearable defaultOptions={convertUser(userList)} />
				);
			}, [filter, setFilter, userList]),
		},
		{
			value: 'startAt',
			title: 'Время прихода',
			width: '100px',
			formatted: React.useCallback((row: ISession) => {
				return (
					<div>
						{row.begin && moment(row.begin.createdAt).format('DD.MM HH:mm')}
					</div>
				);
			}, []),
			filter: () => {
				const pattern = /(Управляющий|Заместитель управляющего)/;
				if (pattern.test(storeStr)) {
					return
				}
				const [startDate, setStartDate] = React.useState(new Date());
				const [endDate, setEndDate] = React.useState();
				const onChange = (dates: any) => {
					const [start, end] = dates;
					setStartDate(start);
					setEndDate(end);
					setFilter({ ...filter, fromDate: start, toDate: end });
				};

				type ButtonProps = React.HTMLProps<HTMLButtonElement>;

				const ExampleCustomInput = React.forwardRef<HTMLButtonElement, ButtonProps>(function Render(
					{ value, onClick },
					ref,
				) {
					return <Button onClick={onClick}>{value}</Button>;
				});

				return (
					<DatePicker
						selected={startDate}
						onChange={onChange}
						startDate={startDate}
						endDate={endDate}
						customInput={<ExampleCustomInput />}
						selectsRange
					/>
				);
			},
		},
		{
			value: 'endAt',
			title: 'Время ухода',
			width: '100px',
			formatted: React.useCallback((row: ISession) => {
				return (
					<div>
						{row.end && moment(row.end.createdAt).format('DD.MM HH:mm')}
					</div>
				);
			}, []),
		},		
		{
			value: 'diff',
			title: 'Сумма часов',
			formatted: React.useCallback((row: ISession) => {
				let diff = 0;
				if (row.begin && row.end) {
					const fromDate = moment(row.begin.createdAt);
					const toDate = moment(row.end.createdAt);
					diff = toDate.diff(fromDate, 'hours');
				}
				return <div>{diff}</div>;
			}, []),
		},
		{
			value: 'type',
			title: 'Тип регистрации',
			formatted: React.useCallback((row: ISession) => {
				return <div>{row?.begin?.type === 'manual' ? 'ручная' : 'автомат'}</div>;
			}, []),
		},		
		{
			value: 'geo',
			title: 'Геолокация (приход/уход)',
			formatted: React.useCallback((row: ISession) => {
				return (
					<div>
						{row?.begin && row?.begin?.lat && (
							<a
								href={`https://geotree.ru/coordinates?lat=${row?.begin?.lat}&lon=${row?.begin?.lon}&z=18&mlat=${row?.begin?.lat}&mlon=${row?.begin?.lon}`}
								target="_black"
							>
								Геотег1
							</a>
						)}
						{row?.end && row?.end?.lat && (
							<a
								href={`https://geotree.ru/coordinates?lat=${row?.end?.lat}&lon=${row?.end?.lon}&z=18&mlat=${row?.end?.lat}&mlon=${row?.end?.lon}`}
								target="_black"
							>
								{' '}
								Геотег2
							</a>
						)}
					</div>
				);
			}, []),
		},
		{
			value: 'photo',
			title: 'Фото',
			width: '200px',
			formatted: React.useCallback((row: ISession) => {
				return (
					<div>
						{row.begin && row.begin?.file &&<ZoomComponent src={host + row.begin?.file?.uri} />}
						{row.end && row.end?.file &&<ZoomComponent src={host + row.end?.file?.uri} />}
					</div>
				);
			}, []),
		},			
		{
			value: 'comment',
			title: 'РД комментарий',
			formatted: React.useCallback((row: ISession) => {
				const update = async (data: any) => {
					patch(`/api/session/${data.id}`, data);
				};

				return (
					<div>
						<OperationFrom row={row} init={load} update={update} />
					</div>
				);
			}, []),
			filter: React.useCallback(() => {
				const params = Object.entries(filter)
					.map(item => item.join('='))
					.join('&');

				return (
					<Button
						onClick={() => {
							window.location.href = host + `/api/session/exportToXls?storeId=${storeId}&${params}`;
						}}
						variant="secondary"
						size="sm"
						endIcon={
							<i>
								<img
									src={FileDownload}
									style={{
										filter: 'invert(22%) sepia(37%) saturate(900%) hue-rotate(193deg) brightness(90%) contrast(83%)',
									}}
								/>
							</i>
						}
					>
						Скачать xls
					</Button>
				);
			}, [filter, setFilter, storeId]),
		},
	];

	return (
		<div>
			{error && 'Error!'}
			<Table headers={headers} rows={data} />
			{pageCount > 1 && <Paginate handlePageClick={handlePageClick} pageCount={pageCount} />}
		</div>
	);
}
