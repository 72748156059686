import React from 'react';
import Button from 'components/UI/Button';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';
import { $store, $storeList } from 'models/store';
import { useUnit } from 'effector-react';
import { IStore } from 'dto/store.dto';

export default function Section() {
	const navigate = useNavigate();

    const store = useUnit($store);
	const storeList = useUnit($storeList);

	const selectStore = React.useCallback((storeId: string) => {
		navigate(`confirm/${storeId}`);
	}, []);

	const handleBack = React.useCallback(() => {
		navigate('/qrcode');
	}, []);

	return (
		<div className={styles["section-list"]}>
			<h2>Выберите секцию</h2>
			{storeList.map((item: IStore, index, self) => {
				return index === self.findIndex(t => (
					t.id === item.id
				)) && <div key ={item.id} className={styles["section-item"]}><Button variant="violet" block onClick={() => selectStore(item.id)}>{item.number} {item.title}</Button></div>
			})}
			<Button variant="green" onClick={handleBack}>
				Назад
			</Button>

		</div>
	);
}
