// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".geo-err-body--jFYMD>div{margin:20px auto}", "",{"version":3,"sources":["webpack://./src/components/GeoError/styles.module.scss"],"names":[],"mappings":"AAAA,yBACI,gBAAA","sourcesContent":[".geo-err-body > div {\n    margin: 20px auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"geo-err-body": "geo-err-body--jFYMD"
};
export default ___CSS_LOADER_EXPORT___;
