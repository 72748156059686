import React from 'react';
import useFetch, { CachePolicies } from 'use-http';
import Button from 'components/UI/Button';
import { useParams, useNavigate } from 'react-router-dom';
import Scanner from 'components/Scanner';
import Input from 'components/UI/Input';
import { Html5QrcodeSupportedFormats } from "html5-qrcode";

const qrConfig = {
  fps: 1,
  qrbox: { width: 350, height: 100 },
  formatsToSupport: [
    Html5QrcodeSupportedFormats.CODE_128
  ]
};

const options: { cachePolicy: CachePolicies; cacheTime: number } = {
	cachePolicy: CachePolicies.CACHE_FIRST,
	cacheTime: 10000,	
}

const host = process.env.REACT_APP_BACKEND_URL;

export default function ScannerPage() {

	const [facingMode, setFacingMode] = React.useState(false);

	const [code, setCode] = React.useState<string>('')
	const [notFound, setNotFound] = React.useState<string>('')

	const handleClick = React.useCallback(() => {
		setFacingMode(!facingMode);
	}, [setFacingMode, facingMode]);

	const { get, loading } = useFetch(host, options);

	const navigate = useNavigate();
	const { mode } = useParams();
	
	const successHandle = React.useCallback(
		(eancode: string) => {
			setCode(eancode)
		},
		[setCode],
	);

    const handleChange = (event:any) => {
		setCode(event.target.value)
    }
    const handleSave = async (event:any) => {
		const data = await get(`/api/users/byBarcode?barcode=${code.trim().slice(-5)}`);
		if (!data?.id) {
			setNotFound('Пользователь не найден')
		} else {
			setNotFound('')
			return navigate(`/manual/accept/${code.trim().slice(-5)}`);
		}
    }

	return (
		<>
			<Button type="button" onClick={handleClick} style={{ margin: '10px auto' }}>Смена камеры</Button>
			<Scanner successCallback={successHandle} mode={mode} config={qrConfig} facingModeUser={facingMode} />
            <div style={{ margin: '40px' }}>
                <Input onChange={handleChange} name="filter" label="Ввести ШК вручную" defaultValue={code}></Input>
            </div>
			<div>{notFound}</div>
			<div className='row'>
				<div className='column-xs-4'>
					<Button variant="green" type="button" onClick={() => navigate('/qrcode')} style={{ margin: '40px auto' }}>
						На главную
					</Button>
				</div>
				<div className='column-xs-8'>
					<Button disabled={loading} variant="green" type="button" onClick={handleSave} style={{ margin: '40px auto' }}>
						{loading ? "Проверка..." : "Подтвердить пользователя"}
					</Button>
				</div>
			</div>

			 {/*<button onClick={async () => successHandle('XqFHHD8zhUkD9ln')}>Click</button> */}
		</>
	);
}
