import React from 'react';
import Button from 'components/UI/Button';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './styles.module.scss';
import { $storeList, setStore } from 'models/store';
import { useUnit } from 'effector-react';
import { IStore } from 'dto/store.dto';

export default function ConfirmSection() {
	const navigate = useNavigate();
    const { storeId } = useParams();

	const storeList = useUnit($storeList);

	const handleBack = React.useCallback(() => {
		navigate('/section');
	}, []);

    const handleConfirm = React.useCallback(() => {
        const found = storeList.find((item: IStore) => item.id === storeId)
        console.log('handleConfirm',{found, storeId})
        if (found) {            
            setStore(found)
        }		
        navigate('/qrcode');
	}, [storeList, storeId])

    const selectedStore = storeList.find((item: IStore) => item.id === storeId)

	return (
		<div className={styles["section-list"]}>
            {selectedStore ? (
                <>
                    <div className={styles["section-item"]}>
                        <h2>Вы хотите сменить секцию на {selectedStore.title}?</h2>
                    </div>

                    <div className={styles["section-item"]} style={{marginTop: "50px"}}>
                        <div className={styles["section-item-btn"]}>
                            <Button variant="green" onClick={handleBack}>
                                Назад
                            </Button>
                        </div>
                        <div className={styles["section-item-btn"]}>
                            <Button variant="violet" onClick={handleConfirm}>
                                Подтвердить
                            </Button>
                        </div>
                    </div>
                </>
            ) : (
                <div>Магазин не найден.</div>
            )}

		</div>
	);
}
