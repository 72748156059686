import React from 'react';
import useFetch from 'use-http';
import { useParams } from 'react-router-dom';
import Paginate from 'components/UI/Paginate';
import usePaginate from 'hooks/usePaginate';
import Table, { TableFilter } from 'components/UI/Table';
import Button from 'components/UI/Button';
import moment from 'moment';
import { BreachEnum, IBreach } from 'types/breach';
import Select from 'components/UI/Select';
import AsyncSelect from 'components/UI/AsyncSelect';
import { ISelectOptions } from 'types/utils';
import { convertUser } from 'utils/helpers';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import OperationFrom from 'components/OperationFrom';
import FileDownload from 'assets/img/svg/download.svg';
import { useUnit } from 'effector-react';
import { $store } from 'models/store';
import { $user } from 'models/user';
import { GroupsEnum } from 'types/users';

const perPage = 10;

const BreachConst = {
	DiffGeo: 'Геотег',
	DiffToken: 'Нарушение авторизации',
	AnotherShift: 'Связанные авторизации',
	ErrorGeo: 'Ошибка координат',
	EndSession: 'Незакрытая сессия',
	RegManual: 'Ручная регистрация'
};

const breachOptions = [
	{ value: 'DiffGeo', label: 'Геотег' },
	{ value: 'DiffToken', label: 'Нарушение авторизации' },
	{ value: 'AnotherShift', label: 'Связанные авторизации' },
	{ value: 'ErrorGeo', label: 'Ошибка координат' },
	{ value: 'RegManual', label: 'Ручная регистрация' },
	{ value: 'EndSession', label: 'Незакрытая сессия' },	
];

const host = process.env.REACT_APP_BACKEND_URL;

export default function SessionsBroken() {
	const store = useUnit($store)
	const user = useUnit($user)
	const { patch, get, loading, error } = useFetch();

	const [data, setData] = React.useState([]);
	const [userList, setUserList] = React.useState([]);
	const [filter, setFilter] = React.useState<TableFilter>(new TableFilter());
	const { pageCount, offset, setCount, handlePageClick } = usePaginate(perPage);

	async function load(filter = '') {
		let url = `/api/breach?offset=${offset}&limit=${perPage}`;

		let storeId = null

		if (user && user.groups && user.groups.indexOf(GroupsEnum.ADMIN) > -1) {
			storeId = ''
		} else if (store?.id) {
			storeId = store?.id
		}

		if (storeId !== null) {
			if (storeId) {
				url += `&storeId=${storeId}`;
			}
			url += `&${filter}`;
	
			const result = await get(url);
			if (result?.rows) {
				setData(result.rows);
				setCount(result.count);
			}			
		}
	}

	React.useEffect(() => {
		init();
	}, []);

	async function init() {
		const resultUser = await get(`/api/users`);
		setUserList(resultUser);
	}

	React.useEffect(() => {
		load(
			Object.entries(filter)
				.map(item => item.join('='))
				.join('&'),
		);
	}, [offset, perPage, filter, store]);

	async function loadUser(inputValue: string, callback: (options: ISelectOptions[]) => void) {
		let data = [];
		const params = new URLSearchParams({
			fio: inputValue,
		});
		if (inputValue) {
			data = await get(`/api/users?${params.toString()}`);
		}

		return convertUser(data);
	}

	const headers = [
		{ value: 'id', title: 'ID' },
		{
			value: 'store',
			title: 'Магазин',
			width: '300px',
			formatted: React.useCallback((row: IBreach) => {
				return <div>{row?.checkin?.store?.title}</div>;
			}, []),
		},
		{
			value: 'user',
			title: 'ФИО',
			width: '500px',
			formatted: React.useCallback((row: IBreach) => {
				return (
					<div>
						<span>{row.user?.firstname} </span>
						<span>{row.user?.secondname} </span>
						<span>{row.user?.middlename} </span>
					</div>
				);
			}, []),
			filter: React.useCallback(() => {
				const handleChange = (newValue: any): void => {
					setFilter({ ...filter, userId: newValue });
				};

				return (
					<AsyncSelect onChange={handleChange} load={loadUser} isClearable defaultOptions={convertUser(userList)} />
				);
			}, [filter, setFilter, userList]),
		},
		{
			value: 'type',
			title: 'Тип нарушения',
			width: '500px',
			formatted: React.useCallback((row: IBreach) => {
				return (
					<span>
						{(row.type === BreachEnum.EndSession || row.type === BreachEnum.RegManual) && <>
							{BreachConst[row.type]}
						</>}
						{row.type === BreachEnum.AnotherShift && <>
							{BreachConst[row.type]}
							<span> ({row.data?.total} раз) </span>
						</>}
						{row.type === BreachEnum.DiffGeo && row.data?.diffGeo && (
							<>
								{row?.checkin && row.checkin.lat && (
									<a
										href={`https://geotree.ru/coordinates?lat=${row.checkin.lat}&lon=${row.checkin.lon}&z=18&mlat=${row.checkin.lat}&mlon=${row.checkin.lon}`}
										target="_black"
									>
										{BreachConst[row.type]}
									</a>
								)}
								<span>({(+(1000 * +row.data?.diffGeo).toFixed(0)).toLocaleString('ru')}) м)</span>
							</>
						)}
						{row.type === BreachEnum.ErrorGeo && (
							<>
								{BreachConst[row.type]}
								<div>
									{row.data?.uri && (
										<a href={row.data?.uri} target="_blank" rel="noreferrer">
											Посмотреть селфи
										</a>
									)}
								</div>
							</>
						)}
					</span>
				);
			}, []),
			filter: React.useCallback(() => {
				const hundleChange = (value: string) => {
					setFilter({ ...filter, type: value });
				};
				return <Select onChange={hundleChange} options={breachOptions} defaultValue={filter.type} />;
			}, [filter, setFilter]),
		},
		{
			value: 'open',
			title: 'Действие',
			formatted: React.useCallback((row: IBreach) => {
				return (
					<div>
						<span>{row?.open === true && 'Приход'}</span>
						<span>{row?.open === false && 'Уход'}</span>
					</div>
				);
			}, []),
		},
		{
			value: 'operation',
			title: 'Время в магазине',
			width: '300px',
			formatted: React.useCallback((row: IBreach) => {
				return (
					<div>
						<span>{moment(row.createdAt).format('DD.MM.YY HH:mm')}</span>
					</div>
				);
			}, []),
			filter: function Filter() {
				const [startDate, setStartDate] = React.useState(new Date());
				const [endDate, setEndDate] = React.useState();
				const onChange = (dates: any) => {
					const [start, end] = dates;
					setStartDate(start);
					setEndDate(end);
					setFilter({ ...filter, fromDate: start, toDate: end });
				};

				type ButtonProps = React.HTMLProps<HTMLButtonElement>;

				const ExampleCustomInput = React.forwardRef<HTMLButtonElement, ButtonProps>(function Render(
					{ value, onClick },
					ref,
				) {
					return <Button onClick={onClick}>{value}</Button>;
				});

				return (
					<DatePicker
						selected={startDate}
						onChange={onChange}
						startDate={startDate}
						endDate={endDate}
						customInput={<ExampleCustomInput />}
						selectsRange
					/>
				);
			},
		},
		{
			value: 'comment',
			title: 'РД комментарий',
			formatted: React.useCallback((row: IBreach) => {
				const update = async (data: any) => {
					patch(`/api/breach/${data.id}`, data);
				};

				return (
					<div>
						<OperationFrom row={row} init={load} update={update} />
					</div>
				);
			}, []),
			filter: React.useCallback(() => {
				if (!!user && Array.isArray(user.groups) && user.groups.indexOf(GroupsEnum.ADMIN) > -1) {

				} else if (store?.id) {
					filter['storeId'] = store.id
				}

				const params = Object.entries(filter)
					.map(item => item.join('='))
					.join('&');

				return (
					<Button
						onClick={() => {
							window.location.href = host + `/api/breach/reportToXls?${params}`;
						}}
						variant="secondary"
						size="sm"
						endIcon={
							<i>
								<img
									src={FileDownload}
									style={{
										filter: 'invert(22%) sepia(37%) saturate(900%) hue-rotate(193deg) brightness(90%) contrast(83%)',
									}}
								/>
							</i>
						}
					>
						Скачать xls
					</Button>
				);
			}, [filter, setFilter, store]),
		},
	];

	return (
		<div>
			{error && 'Error!'}
			<Table headers={headers} rows={data} />
			{pageCount > 1 && <Paginate handlePageClick={handlePageClick} pageCount={pageCount} />}
		</div>
	);
}
