import React, {useState} from 'react';
import useFetch from 'use-http';
import Button from 'components/UI/Button';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';
import { $store } from 'models/store';
import { useUnit } from 'effector-react';
import moment from 'moment';
import { CLOSE, ISession, OPEN, PENDING, UNSUCCESSFUL } from 'types/session';
import Table from 'components/UI/Table';
import usePaginate from 'hooks/usePaginate';
import Paginate from 'components/UI/Paginate';
import cn from "classnames";
import Popup from 'components/Popup';
import { OutsourceRolesEnum } from 'types/users';
import Request from './Request'

interface CashierData {
    cashier_login: string;
    cashier_passw: string;
}

function displayStatus(row: ISession) {
    const {begin, end, status} = row
    switch(true) {
        case status === UNSUCCESSFUL:
            return 'Не состоялась'
        case status === PENDING:
            return 'В ожидании'
        case status === OPEN:
            return 'Открыта'
        case status === CLOSE:
            return 'Закрыта'
        default:
            return ''
    }
}

function displayPosition(row:any) {
    switch(true) {
        case !!row?.outsourcing?.pos?.title:
            return row?.outsourcing?.pos?.title;
        case !!row?.request?.position?.title:
            return row?.request?.position?.title;
        case !!row?.begin?.outsourcing?.position:
            return row?.begin?.outsourcing?.position;
        default:
            return ''
    }
}

function displayCounterParty(row:any) {
    switch(true) {
        case !!row?.outsourcing?.counterparty?.title:
            return row?.outsourcing?.counterparty?.title;
        case !!row?.begin?.outsourcing?.counterparty?.title:
            return row?.begin?.outsourcing?.counterparty?.title;
        case !!row?.request?.counterparty?.title:
            return row?.request?.counterparty?.title;
        default:
            return ''
    }
}

const perPage = 10;

export default function Outsourcing() {
	const navigate = useNavigate();


	return (
		<div className={styles["section-list"]}>
            <h2>Смены аутсорс</h2>

            <h3>Плановые заявки на сегодня</h3>
            <Request status={[PENDING]} />
			
            <h3>Открытые смены</h3>
            <div className={cn(styles["section-item"], styles["section-item-top"])}>
                <Button variant="violet" onClick={() => navigate('add')}>
                    Добавить сотрудника аутсорс
                </Button> 
            </div>
            <Request status={[OPEN,CLOSE]} />
		</div>
	);
}
