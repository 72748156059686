// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".agreement--yqlnW>svg{width:200px;height:200px}.btn--Fx6RJ{margin:20px 20px 0 20px}.btn-section--JxIV0{display:flex;justify-content:center;margin:20px}.agreement--yqlnW{position:absolute;width:100%;text-align:center;top:100px}.agreement-text-exit--V0pSE{font-style:normal;font-weight:500;font-size:20px;line-height:23px;letter-spacing:.142857px;text-decoration-line:underline;color:#535d7e}.agreement--yqlnW>img{margin:auto}.agreement--yqlnW>button{margin:60px auto 0px auto}.agreement--yqlnW>a{margin-top:70px;display:block}", "",{"version":3,"sources":["webpack://./src/pages/QRCode/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,WAAA,CACA,YAAA,CAGJ,YACI,uBAAA,CAGJ,oBACI,YAAA,CACA,sBAAA,CACA,WAAA,CAGJ,kBACI,iBAAA,CACA,UAAA,CACA,iBAAA,CACA,SAAA,CAEA,4BACI,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,wBAAA,CACA,8BAAA,CAEA,aAAA,CAEJ,sBACI,WAAA,CAEJ,yBACI,yBAAA,CAEJ,oBACI,eAAA,CACA,aAAA","sourcesContent":[".agreement > svg {\n    width: 200px;\n    height: 200px;\n}\n\n.btn { \n    margin: 20px 20px 0 20px;\n}\n\n.btn-section {\n    display: flex;\n    justify-content: center;\n    margin: 20px;   \n}\n\n.agreement {\n    position: absolute;  \n    width: 100%;\n    text-align: center;\n    top: 100px;\n\n    &-text-exit {\n        font-style: normal;\n        font-weight: 500;\n        font-size: 20px;\n        line-height: 23px;\n        letter-spacing: 0.142857px;\n        text-decoration-line: underline;\n        \n        color: #535D7E;    \n    }\n    & > img {\n        margin: auto;\n    }\n    & > button {\n        margin: 60px auto 0px auto;\n    }\n    & > a {\n        margin-top: 70px;\n        display: block;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"agreement": "agreement--yqlnW",
	"btn": "btn--Fx6RJ",
	"btn-section": "btn-section--JxIV0",
	"agreement-text-exit": "agreement-text-exit--V0pSE"
};
export default ___CSS_LOADER_EXPORT___;
