import styles from './styles.module.scss';

export default function Border({ children }: { children: React.ReactNode }) {
	return (
		<div className={styles["border-top"]}>
			<div className={styles["border-left"]}>
				<div className={styles["border-bottom"]}>{children}</div>
			</div>
		</div>
	);
}
