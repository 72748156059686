import React from 'react';
import styles from './styles.module.scss';
import UserForm from './UserForm';
import { useNavigate } from 'react-router-dom';
import useFetch from 'use-http';
import { $store } from 'models/store';
import { useUnit } from 'effector-react';

export default function AddUser() {

	const navigate = useNavigate();
    const { post, get } = useFetch();
    const store = useUnit($store);

	const handleSubmit = async (data:any) => {
        const outsUser = await post('api/users/outsource', {
            firstname: data.firstname,
            secondname: data.secondname,
            middlename: data.middlename,
            document_series: data.document_series,
            document_number: data.document_number,
        });
        const userId = outsUser.id;

        const outsourceBody = {
            storeId: store?.id,
            userId,
            counterpartyId: data?.counterpartyId,
            positionId: data?.positionId,
            position: data.position
        }

        const outsource = await post('/api/outsourcing', outsourceBody);

		return navigate(`/outsourcing/photo/${outsource.id}/${userId}`);
	};

	return (
		<div className={styles["section-list"]}>
            <h2>Заполните обязательные поля</h2>
            <UserForm onSubmit={handleSubmit}/>
        </div>
    )
}