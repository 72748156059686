import * as React from 'react'
import { Route, Routes, Outlet, Navigate } from 'react-router-dom'

import { useKeycloak } from '@react-keycloak/web'
import { useUnit } from 'effector-react'
import { $noCoord, $token, $user, setCoordinate } from 'models/user'
import { GroupsEnum } from 'types/users'
import Login from 'components/Login';
import Home from 'pages/Home';
import useFetch, { CachePolicies, Provider } from 'use-http'
import Maps from 'pages/Maps';
import Photo from 'pages/Photo';
import Scanner from 'pages/Scanner';
import History from 'pages/History';
import AdminLayout from 'components/AdminLayout'
import Stores from 'pages/admin/Stores'
import Sessions from 'pages/admin/Sessions'
import SessionsBroken from 'pages/admin/Sessions/SessionsBroken'
import { $store, $storeList, $storeStr, setStore, setStoreList } from 'models/store'
import QRCode from 'pages/QRCode'
import Manual from 'pages/Manual'
import AcceptUser from 'pages/Manual/AcceptUser'
import PhotoUser from 'pages/Manual/PhotoUser'
import moment from 'moment'
import { usePosition } from 'hooks/usePosition'
import Layout from 'components/Layout'
import Forbidden from 'components/Forbidden'
import NotFound from 'components/NotFound'
import { IStoreItem } from 'dto/store.dto'
import Section from 'pages/Section'
import ConfirmSection from 'pages/Section/ConfirmSection'
import Outsourcing from 'pages/Outsourcing'
import AddUser from 'pages/Outsourcing/AddUser'
import PhotoOutsourceUser from 'pages/Outsourcing/PhotoUser'
import AcceptCheckin from 'pages/Outsourcing/AcceptCheckin'
import GeoError from 'components/GeoError'
import OpenShift from 'pages/Outsourcing/OpenShift'
import AnotherUser from 'pages/Outsourcing/AnotherUser'
import EditUser from 'pages/Outsourcing/EditUser'

moment.locale('ru');

const host = process.env.REACT_APP_BACKEND_URL;

const ProtectedRoute = ({
  isAllowed = false,
  redirectPath = `/login`,
  children,
}: {isAllowed?: boolean; redirectPath?: string; children?: React.ReactNode}) => {

  const {keycloak} = useKeycloak();
  const encodedRef = encodeURIComponent(location.pathname)
  const user = useUnit($user);
  const store = useUnit($store);
  const storeStr = useUnit($storeStr)
  const storeList = useUnit($storeList);
  const { get } = useFetch();
	const { position, error } = usePosition();
  const noCoord = useUnit($noCoord);

	React.useEffect(() => {
		if (position?.latitude && position?.longitude) {
			setCoordinate({latitude: position?.latitude, longitude:position?.longitude});
		}
	}, [position, setCoordinate]);

  React.useEffect(() => {
    async function loadStore() {
      if (
        user && 
        Array.isArray(user.groups) && 
        (
          user.groups.indexOf(GroupsEnum.GEN_QRCODE) > -1 ||
          user.groups.indexOf(GroupsEnum.MANUAL_REG) > -1 ||
          user.groups.indexOf(GroupsEnum.SESSION_STAT) > -1          
        )
      ) {

				try {
          // TODO refact
					let storesObject: IStoreItem[] = []
          
          if (storeStr) {      
            const correctedJsonString = storeStr.replace(/'/g, '"');
            storesObject = JSON.parse(correctedJsonString);
            if (storesObject?.length) {
              const  storesList = []
              for (let i = 0; i < storesObject?.length; i++) {
                if (storesObject[i].storeId) {
                  const store = await get(`/api/stores/num/${storesObject[i].storeId}`);
                  storesList.push(store)  
                }
              }
              setStoreList(storesList)
              const priorityStore = storesObject.find((item: IStoreItem) => +item.position_type === 1)
              if (priorityStore?.storeId && !store?.id) {
                const store = await get(`/api/stores/num/${priorityStore.storeId}`);
                setStore(store)
              }
            }
          }
				} catch (e) {
					console.log(e)
				}
      }
    }
    if (user) {
      loadStore();
    }

  }, [user, storeStr])  

	if ((error || !position?.latitude) && !noCoord) {
		return <GeoError message={error ? error : "Информация о местоположении недоступна."} />;
	}

  console.log('ProtectedRoute', {isAllowed, user, redirectPath, authenticated: keycloak.authenticated, encodedRef})
  if (!user) {
    return <Navigate to={redirectPath+`?ref=${encodedRef}`} replace />;
  }
  if (!keycloak?.authenticated) {
    return <Navigate to={redirectPath+`?ref=${encodedRef}`} replace />;
  } 
  if (!isAllowed) {
    return <Forbidden />;
  }  

  return  React.isValidElement(children) ? children : <Outlet />;
};  

 const AppRouter = () => {

  const user = useUnit($user);
  const token = useUnit($token);
  const storeList = useUnit($storeList);

  const { initialized } = useKeycloak()

  if (!initialized) {
    return <div>Загрузка...</div>
  }
  
	const globalOptions = {
		cachePolicy: CachePolicies.NO_CACHE,
		interceptors: {
			request: ({ options }: { options: RequestInit }) => {
				const newOptions = options;
				newOptions.headers = {
					Authorization: `Bearer ${token}`,
					...options.headers,
				};
				return newOptions;
			},
		},
	};

  return (
    <Provider url={host} options={globalOptions}>
      <Routes>        
          <Route element={
              <ProtectedRoute 
                isAllowed={!!user && Array.isArray(user.groups) && user.groups.indexOf(GroupsEnum.CLIENT) > -1} 
              />
            }
          >
              <Route path="" element={<Home />} />
              <Route path="maps/:code" element={<Layout ><Maps /></Layout >} />
              <Route path="photo/:code" element={<Layout ><Photo /></Layout>} />
              <Route path="scanner/:mode" element={<Layout ><Scanner /></Layout>} />
              <Route path="history" element={<History />} />          
          </Route>

          <Route element={
              <ProtectedRoute 
                isAllowed={
                  !!user && 
                  Array.isArray(user.groups) &&
                  storeList?.length > 0 &&
                  (
                    user.groups.indexOf(GroupsEnum.GEN_QRCODE) > -1 ||
                    user.groups.indexOf(GroupsEnum.MANUAL_REG) > -1 ||
                    user.groups.indexOf(GroupsEnum.SESSION_STAT) > -1                  
                  )
                } 
              />
            }
          >
            <Route path="" element={<Layout />}>
              <Route path="qrcode"  element={<QRCode />} />            
              <Route path="section" element={<Section />} />
              <Route path="section/confirm/:storeId" element={<ConfirmSection />} />
              <Route path="manual" element={<Manual />} />
              <Route path="manual/accept/:code" element={<AcceptUser />}  />
              <Route path="manual/photo/:barcode" element={<PhotoUser />} />
            </Route>
          </Route>

          <Route element={
              <ProtectedRoute 
                isAllowed={
                  !!user && 
                  Array.isArray(user.groups) &&
                  storeList?.length > 0 &&
                  (
                    user.groups.indexOf(GroupsEnum.OUTSOURCE) > -1
                  )
                } 
              />
            }
          >
            <Route path="" element={<Layout />}>
              <Route path="outsourcing" element={<Outsourcing />} />
              <Route path="outsourcing/add" element={<AddUser />} />
              <Route path="outsourcing/edit/:outsourceId" element={<EditUser />} />
              <Route path="outsourcing/open/:sessionId" element={<OpenShift />} />
              <Route path="outsourcing/another/:sessionId" element={<AnotherUser />} />
              <Route path="outsourcing/accept/:outsourceId/:mode/:imageId?/:sessionId?" element={<AcceptCheckin />} />
              <Route path="outsourcing/photo/:outsourceId/:userId/:sessionId?" element={<PhotoOutsourceUser />} />
            </Route>
          </Route>

          <Route
              path="/admin"
              element={
                  !!user && Array.isArray(user.groups) && (user.groups.indexOf(GroupsEnum.SESSION_STAT) > -1 || user.groups.indexOf(GroupsEnum.ADMIN) > -1 ) ? (
                      <Navigate to="/admin/stores" />
                  ) : !!user && Array.isArray(user.groups) &&  user.groups.indexOf(GroupsEnum.BREACH_STAT) > -1 ? (
                      <Navigate to="/admin/broken" />
                  ) : (
                      <Navigate to="/" />
                  )
              }
          />

          <Route
            path="/admin/stores"
            element={
              <ProtectedRoute
                isAllowed={
                  !!user && 
                  Array.isArray(user.groups) && 
                  (
                    user.groups.indexOf(GroupsEnum.ADMIN) > -1 ||
                    user.groups.indexOf(GroupsEnum.SESSION_STAT) > -1
                  )
                }
              />
            }
          >  
            <Route element={<AdminLayout />  } >
              <Route path="" element={<Stores />} />
              <Route path=":storeId/sessions" element={<Sessions />} />
            </Route>   
          </Route>

          <Route
            path="/admin/broken"
            element={
              <ProtectedRoute
                isAllowed={
                  !!user && 
                  Array.isArray(user.groups) && 
                  (
                    user.groups.indexOf(GroupsEnum.ADMIN) > -1 ||
                    user.groups.indexOf(GroupsEnum.BREACH_STAT) > -1
                  )
                }
              />
            }
          >  
            <Route element={<AdminLayout />  } >
              <Route path="" element={<SessionsBroken />} />
              </Route>   
          </Route>   

          <Route path="login" element={<Login />} />
          <Route path="*" element={<NotFound />} />
      </Routes>
    </Provider>
  )
}

export default AppRouter;