import styles from './styles.module.scss';
import Border from 'components/Border';
import Button from 'components/UI/Button';
import WelcomeUserIcon from 'assets/img/pic/welcome_user.png';
import {IUser} from 'types/users'

export default function AgreementUser({onAccept, onCancel, user} : {onAccept: () => void, onCancel: () => Promise<void>|void, user: Partial<IUser>}) {

	return (
        <Border>
            <div className={styles.agreement}>
                <img src={WelcomeUserIcon} />
                <p>ВЫ</p>
                <h2>{[user.firstname, user.secondname, user.middlename].join(' ')}? </h2>
                <Button variant="green" type="button" onClick={onAccept} size="lg">
                    ДА
                </Button>
                <a onClick={onCancel} className={styles['agreement-text-exit']} href="#">
                    Нет, ошибка
                </a>
            </div>
        </Border>        
    )
}
